const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  plugins: [require('./tailwind-scrollbar-hide')],
  theme: {
    colors: {
      transparent: 'transparent',
      white: {
        DEFAULT: '#ffffff',
      },
      primary: {
        DEFAULT: '#43AA8B',
        25: '#F9FCFA',
        50: '#F7FDFB',
        100: '#F2F9F7',
        200: '#E1F2ED',
        400: '#AAD9CB',
        500: '#43AA8B',
        600: '#3A987C',
        800: '#30866C',
      },
      shades: {
        DEFAULT: '#979797',
        25: '#FAFAFA',
        35: '#F4F4F4',
        40: '#F0F0F0',
        45: '#EFEFEF',
        50: '#ECECEC',
        100: '#DFDFDF',
        200: '#DADBDD',
        300: '#B2B2B2',
        350: '#A8ABB1',
        400: '#797A7D',
        600: '#404145',
        800: '#252528',
        1000: '#000000',
      },
      orange: {
        DEFAULT: '#EFAF66',
        50: '#FFF0DF',
        100: '#FCD45E',
        200: '#F5CEA1',
        400: '#EFAF66',
        600: '#E78414',
        800: '#D0750D',
      },
      red: {
        DEFAULT: '#DF8480',
        25: '#FCF4F3',
        50: '#FBF2F1',
        200: '#F0C6C4',
        400: '#DF8480',
        500: '#D6605A',
        600: '#CE423B',
        800: '#B8231C',
        850: '#BD6D6E',
        900: '#B11B14',
      },
      purple: {
        DEFAULT: '#9F86C0',
      },
    },
    fontFamily: {
      sans: ['var(--font-poppins)', 'Poppins', ...defaultTheme.fontFamily.sans],
      serif: [...defaultTheme.fontFamily.serif],
      mono: [...defaultTheme.fontFamily.mono],
    },
    fontSize: {
      //
      // https://nekocalc.com/px-to-rem-converter
      //
      xxs: '.57rem', // 9.12px
      xs: '.625rem', // 10px
      sm: '.75rem', // 12px
      base: '.875rem', // 14px
      lg: '1rem', // 16px
      xl: '1.25rem', // 20px
      xxl: '1.50rem', // 24px
      xxxl: '1.875rem', // 30px
    },
    dropShadow: {
      DEFAULT: '0px 0px 30px rgba(0, 0, 0, 0.1)',
      none: '0 0px 0px rgb(0 0 0 / 0.0)',
      xs: '0 1px 1px rgb(0 0 0 / 0.1)',
      sm: '0 2px 2px rgb(0 0 0 / 0.1)',
      md: '0 20px 20px rgb(0 0 0 / 0.1)',
    },
    lineHeight: {
      5: '22px',
      6: '24px',
      7: '26px',
    },
    gradient: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)',
    minWidth: {
      '5xl': '864px',
    },
    maxWidth: {
      '7xl': '1240px',
    },
  },
  variants: {
    extend: {},
  },
  safelist: [
    'md:mb-5',
    'md:mb-3',
    '-mt-6',
    'w-[10%]',
    'w-[15%]',
    'w-[16%]',
    'w-[17%]',
    'w-[18%]',
    'w-[20%]',
    'w-[30%]',
    'w-[32%]',
    'w-[35%]',
    'w-[40%]',
    'w-[55%]',
    'w-[60%]',
    'w-[65%]',
    'w-[70%]',
    'w-[75%]',
    'w-[80%]',
    'w-[85%]',
    'w-[90%]',
    'w-[95%]',
    {
      pattern: /top|bottom|pr|pl-.+/,
    },
    {
      pattern: /col-span-.+/,
    },
  ],
}
